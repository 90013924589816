* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {

  .App {
    height: 3000px;
    position: relative;
    flex-direction: column;
  }

  .Hero-sector {
    position: relative;
    height: 750px;
    overflow: hidden;
  }

  .person-image,
  .person-bubble,
  .bubble1,
  .bubble2,
  .bubble3 {
    display: none;
  }

  .main-logo {
    position: absolute;
    top: 20%;
    left: 40%; 
    width: 70%;
    max-width: 300px;
    transform: translate(-50%,-50%);
    z-index: 3;
  }

  .nav-item-1{
    position: absolute;
    top: 1.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    width: auto;
    height: auto;
    z-index: 4;
    text-align: left;
    list-style-type: none;
  }
  
  .nav-item-1 li{
    display: inline-block;
    margin-right: 1rem;
  }
  
  .nav-item-1 a {
    color: #fff;
    font: normal normal 700 6vw/6vh Lato;
    text-decoration: none;
  }
  
  .nav-item-2{
    position: absolute;
    top: 1.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    width: auto;
    height: auto;
    z-index: 4;
    text-align: left; 
    list-style-type: none;
  }
  
  .nav-item-2 li{
    display: inline-block;
    margin-right: 1rem;
  }
  
  .nav-item-2 a {
    color: #fff;
    font: normal normal 700 6vw/6vh Lato;
    text-decoration: none;
  }

  .bubble-1 {
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .bubble-2 {
    position: absolute;
    top: 75%;
    left: 30%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .bubble-3 {
  position: absolute;
  top: 55%;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

  .text-container{
    position: absolute;
    top: 30%;
    left: 10%;
    width: 80%;
    max-width: 550px;
    z-index: 3;
    text-align: center;
    color: #fff;
    font: normal normal 900 14px/40px Lato;
  }

  .text-container-2 {
    position: absolute;
    top: 30%;
    left: 10%;
    max-width: 80%;
    width: auto;
    height: auto;
    z-index: 3;
    text-align: center;
    color: #fff;
    font: normal normal 900 14.5px/30px Lato;
  }

  .secondary-text-container {
    position: absolute;
    top: 50%;
    left: 29.5%;
    transform: translate(-22.5%);
    max-width: 80%;
    z-index: 3;
    text-align: center;
    color: #fff;
    opacity: 0.5;
    font: normal normal 900 14.5px/30px Lato;
  }

  .Button-container {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
  
  .my-button {
    min-width: 350px;
    min-height: 90px;
    width: 95%;
    max-width: 800px;
    height: 9%;
    /* UI Properties */
    background: #00BAFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    opacity: 1;
    border-style: none;
    text-align: center;
    font: normal normal 900 30px/48px Lato;
    letter-spacing: 0px;
    color: #FBFBFB;
    text-shadow: 0px 3px 6px #00000029;
    text-transform: capitalize;
    opacity: 1;
  }
  
  
  .email-form {
    display: flex;
    /* add this line */
    justify-content: space-between;
    /* add this line */
    min-width: 350px;
    min-height: 50px;
    width: 350px;
    height: 5%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 10px solid #00BAFF;
    border-radius: 18px;
    opacity: 1;
    position: relative;
  }
  
  .email-form-input {
    margin-top: 3%;
    margin-left: 5%;
    width: 75.3%;
    height: 50px;
    /* increase height */
    /* UI Properties */
    text-align: left;
    font: normal normal 900 15px/20px Lato;
    letter-spacing: 0px;
    color: #00000021;
    border-style: none;
  }
  
  
  .submit-button {
    position: relative;
    min-width: 80px;
    min-height: 70px;
    /* UI Properties */
    background: #00BAFF 0% 0% no-repeat padding-box;
    opacity: 1;
    border-style: none;
  }
  
  .email-form input[type="email"] {
    margin-right: 10px;
    border: none;
    outline: none;
    font-size: 20px;
    background-color: #fff;
    color: #000;
  }
  
  .email-form-input::placeholder {
    font-size: 20px;
  }
  
  .email-form-input:focus,
  .email-form-input:valid {
    font-size: 20px;
  }
  
  .email-submitted {
    font-size: 20px;
  }

  .HDIW-text {
    position: absolute;
    top: 26%;
    left: 17.5%;
    z-index: 3;
    transform: translate(-10%, -10%);
    text-align: center;
    color: #15616D;
    font: normal normal 900 18px/50px Lato;
  }
  
  .lottie-ani-1 {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 380px;
  }
  
  
  .stap-1 {
    position: absolute;
    top: 32.5%;
    left: 5%;
    z-index: 3;
    text-align: center;
    color: #15616D;
    font: normal normal 900 25px/60px Lato;
  }
  
  .lottie-ani-2 {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 300px;
  }
  
  .stap-2 {
    position: absolute;
    top: 52.5%;
    left: 18%;
    z-index: 3;
    text-align: center;
    color: #15616D;
    font: normal normal 900 25px/60px Lato;
  }
  
  .lottie-ani-3 {
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 350px;
  }
  
  .stap-3 {
    position: absolute;
    top: 72.5%;
    left: 18%;
    transform: translate(-10%, -10%);
    z-index: 3;
    text-align: center;
    color: #15616D;
    font: normal normal 900 25px/60px Lato;
  }

  
.facebook-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 38%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
}

.linkedIn-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 63%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
}

.instagram-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 12%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
  color: #15616D;
}

.linkedIn-button svg {
  color: #15616d;
}
.facebook-button svg {
  color: #15616d;
}
.instagram-button svg {
  color: #15616d;
}

}

@media (min-width: 769px) {

.App {
  height: 2048px;
  position: relative;
  flex-direction: column;
}

.nav-item-1{
  position: absolute;
  top: 4%;
  left: 92.5%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 3;
  text-align: left;
  list-style-type: none;
}

.nav-item-1 li{
  display: inline-block;
  margin-right: 1rem;
}

.nav-item-1 a {
  color: #fff;
  font: normal normal 700 2vw/6vh Lato;
  text-decoration: none;
}

.nav-item-2{
  position: absolute;
  top: 4%;
  left: 92.5%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 3;
  text-align: left; 
  list-style-type: none;
}

.nav-item-2 li{
  display: inline-block;
  margin-right: 1rem;
}

.nav-item-2 a {
  color: #fff;
  font: normal normal 700 2vw/6vh Lato;
  text-decoration: none;
}

.main-logo {
  position: absolute;
  top: 12.5%;
  left: 52.5%;
  width: 90%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.Hero-sector {
  position: relative;
  height: 850px;
  overflow: hidden;
}

.Hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.person-image {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 3;
  max-width: 80%;
  max-height: 80%;
}

.person-bubble {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 2;
  max-width: 80%;
  max-height: 80%;
}

@media screen and (max-width: 1500px) {
  .person-image, .person-bubble {
    top:36.5%;
    max-width: 30%;
    max-height: 50%;
  }
}

.bubble-1 {
  position: absolute;
  top: 30%;
  left: 15%;
  width: 80%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 2;
}

.bubble-2 {
  position: absolute;
  top: 75%;
  left: 22.5%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 2;
}

.bubble-3 {
  position: absolute;
  top: 55%;
  left: 45%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 2;
}

.text-container {
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 3;
  text-align: left;
  color: #fff;
  font: normal normal 900 1.5vw/6vh Lato;
}

.text-container-2 {
  position: absolute;
  top: 30%;
  left: 23%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: auto;
  height: auto;
  z-index: 3;
  text-align: left;
  color: #fff;
  font: normal normal 900 1.5vw/6vh Lato;
}

.secondary-text-container {
  position: absolute;
  top: 52.5%;
  left: 30%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  width: auto;
  height: auto;
  z-index: 3;
  text-align: left;
  color: #fff;
  opacity: 0.5;
  font: normal normal 900 calc(0.85vw + 0.9vh)/6vh Lato;
  line-height: 2;
}

.Button-container {
  position: absolute;
  top: 75%;
  left: 25%;
  transform: translateX(-50%);
  z-index: 3;
}

.my-button {
  min-width: 450px;
  min-height: 90px;
  width: 95%;
  max-width: 800px;
  height: 9%;
  /* UI Properties */
  background: #00BAFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  opacity: 1;
  border-style: none;
  text-align: center;
  font: normal normal 900 30px/48px Lato;
  letter-spacing: 0px;
  color: #FBFBFB;
  text-shadow: 0px 3px 6px #00000029;
  text-transform: capitalize;
  opacity: 1;
}


.email-form {
  display: flex;
  /* add this line */
  justify-content: space-between;
  /* add this line */
  min-width: 450px;
  min-height: 50px;
  width: 450px;
  height: 5%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 10px solid #00BAFF;
  border-radius: 18px;
  opacity: 1;
  position: relative;
}

.email-form-input {
  margin-top: 3%;
  margin-left: 5%;
  width: 75.3%;
  height: 50px;
  /* increase height */
  /* UI Properties */
  text-align: left;
  font: normal normal 900 15px/20px Lato;
  letter-spacing: 0px;
  color: #00000021;
  border-style: none;
}


.submit-button {
  position: relative;
  min-width: 80px;
  min-height: 70px;
  /* UI Properties */
  background: #00BAFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-style: none;
}

.email-form input[type="email"] {
  margin-right: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background-color: #fff;
  color: #000;
}

.email-form-input::placeholder {
  font-size: 20px;
}

.email-form-input:focus,
.email-form-input:valid {
  font-size: 20px;
}

.email-submitted {
  font-size: 20px;
}

.HDIW-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  color: #15616D;
  font: normal normal 900 30px/60px Lato;
}

.lottie-ani-1 {
  position: absolute;
  top: 61%;
  left: 21%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 35%;
  height: auto;
}

.stap-1 {
  position: absolute;
  top: 78.5%;
  left: 21%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  color: #15616D;
  font: normal normal 900 1.8vw/5vw Lato;
}

.lottie-ani-2 {
  position: absolute;
  top: 63.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 25%;
  height: auto;
}

.stap-2 {
  position: absolute;
  top: 81%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  color: #15616D;
  font: normal normal 900 1.8vw/5vw Lato;
}

.lottie-ani-3 {
  position: absolute;
  top: 66%;
  left: 79%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 30%;
  height: auto;
}

.stap-3 {
  position: absolute;
  top:85%;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  max-width: 80%;
  color: #15616D;
  font: normal normal 900 1.7vw/4vw Lato;
}

.facebook-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 46%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
}

.linkedIn-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 52%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
}

.instagram-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 80px;
  justify-content: center;
  left: 40%;
  padding: 8px;
  position: absolute;
  top: 94%;
  transition: background-color .3s ease;
  color: #15616D;
}

.linkedIn-button svg {
  color: #15616d;
}
.facebook-button svg {
  color: #15616d;
}
.instagram-button svg {
  color: #15616d;
}




}